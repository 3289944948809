<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from 'axios';
import Disclaimer from '@/components/widgets/disclaimer';
import { authComputed } from "@/state/helpers";
import firebase from 'firebase/compat/app';
import "firebase/compat/functions";
import Subscribe from "@/components/subscribe";

/**
 * Crypto Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Disclaimer, Subscribe },
  data() {
    return {
      // selectedScope: 'mediumterm',
      upgradeBtnLoading: false,
      cryptosLoaded: false,
      stocksLoaded: false,
      etfsLoaded: false,
      title: "Dashboard",
      scopeButtonHoveredLeft: false,
      scopeButtonHoveredRight: false,
      
      headers: {
        cryptos: {
          intraday: ['', 'Name', 'Price', 'Intraday', 'L7d %Δ', 'Rating*'],
          shortterm: ['', 'Name', 'Price', 'Last 7d', 'L7d %Δ', 'Rating*'],
          mediumterm: ['', 'Name', 'Price', 'Last 30d', 'L30d %Δ', 'Rating*'],
          longterm: ['', 'Name', 'Price', 'Last 200d', 'L200d %Δ', 'Rating*']
        },
        stocks: {
          intraday: ['Name', 'Price', 'Intraday', 'L7d %Δ', 'Rating*'],
          shortterm: ['Name', 'Price', 'Last 7d', 'L7d %Δ', 'Rating*'],
          mediumterm: ['Name', 'Price', 'Last 30d', 'L30d %Δ', 'Rating*'],
          longterm: ['Name', 'Price', 'Last 200d', 'L200d %Δ', 'Rating*']
        }
      },

      tdClasses: [
        '',
        'td-truncate',
        '',
        'd-none d-sm-table-cell d-md-none d-xl-table-cell',
        'd-none d-sm-table-cell d-md-none d-xxl-table-cell',
        ''
      ],
      thClasses: [
        '', 
        '', 
        'text-end', 
        'text-center d-none d-sm-table-cell d-md-none d-xl-table-cell',
        'text-end d-none d-sm-table-cell d-md-none d-xxl-table-cell',
        'text-end'
      ],
      
      scopes: {
        intraday: {
          value: "intraday",
          snake: "intraday",
          train: "Intraday",
          kebab: "intraday"
        },
        shortterm: {
          value: "shortterm",
          snake: "short_term",
          train: "Short-Term",
          kebab: "short-term"
        },
        mediumterm: {
          value: "mediumterm",
          snake: "medium_term",
          train: "Medium-Term",
          kebab: "medium-term"
        },
        longterm: {
          value: "longterm",
          snake: "long_term",
          train: "Long-Term",
          kebab: "long-term",
        }
      },

      terms: {
        selectedTerm:
          {
            value: "shortterm",
            snake: "short_term",
            train: "Short-Term",
            kebab: "short-term"
          },
        options: [
          {
            value: "intraday",
            snake: "intraday",
            train: "Intraday",
            kebab: "intraday"
          },
          {
            value: "shortterm",
            snake: "short_term",
            train: "Short-Term",
            kebab: "short-term"
          },
          {
            value: "mediumterm",
            snake: "medium_term",
            train: "Medium-Term",
            kebab: "medium-term"
          },
          {
            value: "longterm",
            snake: "long_term",
            train: "Long-Term",
            kebab: "long-term",
          }
        ]
      },

      breadcrumbs: [],
      cryptos: [],
      stocks: [],
      etfs: [],

      sparkline: {
        chartOptions: {
          chart: {
            type: 'area',
            height: 30,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          // colors: ['#ff0000'],
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [25, 100, 100, 100]
            },
          },
          tooltip: {
            enabled: false,
            // fixed: {
            //   enabled: false
            // },
            // x: {
            //   show: false
            // },
            // marker: {
            //   show: false
            // }
          }
        }
      }
    };
  },
  computed: {
    ...authComputed,
    selectedScope() {
      return this.userData.preferredTerm
    },
    dataLoaded () {
      if(this.cryptosLoaded && this.stocksLoaded && this.userData.preferredTerm) {
        return true
      }
      else return false
    },
  //   selectedScope: {
  //     get() {
  //       return this.$store ? this.$store.state.settings.selectedScope : {} || {};
  //     },
  //     set(scope) {
  //       this.changeScope({
  //         selectedScope: scope,
  //       });
  //     },
  //   },
  },
  created() {
    this.getCryptoListing()
    this.getStocksListing()
    this.getEtfsListing()
  },
  methods: {
    toggleScope(currentScope) {
      var items = ['shortterm', 'mediumterm', 'longterm'];
      var currentIndex = items.indexOf(currentScope);
      var nextIndex = (currentIndex + 1) % items.length;
      this.selectedScope = items[nextIndex];
    },

    setScope(newScope) {
      this.selectedScope = newScope;
    },

    getCryptoListing() {
      let limit = 5
      axios.get(
        'https://api.bulldata.ai/crypto/listings/?limit=' + limit,
        { headers: { token: process.env.VUE_APP_BULLDATA_API_TOKEN } }
      )
      .then(
        result => {
          this.cryptos = result.data.data
          this.cryptosLoaded = true
          console.log("Cryptos loaded.")
        },
        error => {
          console.error(error)
        }
      )
    },

    getStocksListing() {
      let limit = 5
      axios.get(
        'https://api.bulldata.ai/stocks/listings/?limit=' + limit,
        { headers: { token: process.env.VUE_APP_BULLDATA_API_TOKEN } }
      )
      .then(
        result => {
          this.stocks = result.data.data
          this.stocksLoaded = true
          console.log("Stocks loaded.")
        },
        error => {
          console.error(error)
        }
      )
    },

    getEtfsListing() {
      let limit = 5
      axios.get(
        'https://api.bulldata.ai/etfs/listings/?limit=' + limit,
        { headers: { token: process.env.VUE_APP_BULLDATA_API_TOKEN } }
      )
      .then(
        result => {
          this.etfs = result.data.data
          this.etfsLoaded = true
          console.log("ETFs loaded.")
        },
        error => {
          console.error(error)
        }
      )
    },

    shortenString(name, length) {
      if (name.length > length + 2) {
        return name.substring(0, length) + ' …'
      }
      else return name
    },

    formatPrice(price) {
      if (price >= 1) {
        return new Intl.NumberFormat(
          'en-US', 
          { 
            style: 'currency', 
            currency: 'USD', 
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }
        ).format(price)
      }
      else {
        return new Intl.NumberFormat(
          'en-US', 
          { 
            style: 'currency', 
            currency: 'USD', 
            minimumFractionDigits: 4,
            maximumFractionDigits: 4
          }
        ).format(price)
      }
    },

    formatPercent (percent) {
      return new Intl.NumberFormat(
        'en-US', 
        { 
          style: 'percent', 
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          signDisplay: 'always'
        }
      ).format(percent)
    },

    setBadgeClass(itm, term) {
      return {
        'badge badge-score-label badge-strong-buy': itm['score_labels']['score_label_' + term] === 'strong buy',
        'badge badge-score-label badge-strong-sell': itm['score_labels']['score_label_' + term] === 'strong sell',
        'badge badge-score-label badge-buy': itm['score_labels']['score_label_' + term] === 'buy',
        'badge badge-score-label badge-sell': itm['score_labels']['score_label_' + term] === 'sell',
        'badge badge-score-label badge-neutral': itm['score_labels']['score_label_' + term] === 'neutral'
      }
    },

    toSparklineSeries(array, symbol) {
      return [{
        name: symbol,
        data: array
      }]
    },

    getSparklineColor(itm) {
      if (this.selectedScope === 'shortterm') {
        return (itm.rel_deltas.rel_delta_7_days < 0) ? ['#ff0000'] : ['#008000']
      }
      else if (this.selectedScope === 'mediumterm') {
        return (itm.rel_deltas.rel_delta_30_days < 0) ? ['#ff0000'] : ['#008000']
      }
      else if (this.selectedScope === 'longterm') {
        return (itm.rel_deltas.rel_delta_200_days < 0) ? ['#ff0000'] : ['#008000']
      }
    },
    
    getSparklineChartOptions(itm) {
      return {
        chart: {
          type: 'area',
          height: 40,
          sparkline: {
            enabled: true
          }
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        colors: this.getSparklineColor(itm),
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [25, 100, 100, 100]
          },
        },
        tooltip: {
          enabled: false,
          // fixed: {
          //   enabled: false
          // },
          // x: {
          //   show: false
          // },
          // marker: {
          //   show: false
          // }
        }
      }
    },
    
    async openStripePortal(action) {
      if (action === 'cancel') {
        this.cancelBtnLoading = true
      } else {
        this.upgradeBtnLoading = true
      }
      await firebase.functions().httpsCallable('getStripePortalUrl')()
        .then(res => {
          console.log('url: ' + res.data)
          window.location.href = res.data
        })
        .catch(error => {
          console.log(error.message)
        })
    },
  },
};
</script>

<style scoped>
.badge-term {
  font-size: 14px;
  font-weight: 300;
  padding: 1em 1em;
  border-radius: 0.1rem;
  background-color: #000000;

  /* display: inline-block;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; */
}
.badge-score-label {
  font-size: 12px;
  font-weight: 300;
  padding: 0.5em 0.5em;

  /* display: inline-block;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; */
}
.badge-locked {
  font-size: 12px;
  font-weight: 300;
  padding: 0.5em 0.5em;
  color: #495057;
  background-color: #DBE2E9;
}
.badge-buy {
  background-color: #31bc89;
}
.badge-sell {
  background-color: #ff0000;
}
.badge-strong-buy {
  background-color: #31bc89;
}
.badge-strong-sell {
  background-color: #ff0000;
}
.badge-neutral {
  background-color: #5f6875;
}
.card-body-listings {
  padding: 12px;
}
.card-subtitle {
  font-size: 14px;
}
.dash-card {
  background-color: #fff;
  box-shadow: none;
}
.td-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 0;
}
.pointer {
  cursor: pointer;
}
.sidebar-subscribe {
  align-items: center;
  text-align: center;
  vertical-align: middle;
  margin: 25px 20px;
  font-size: 14px;
  font-weight: 300;
}
.signup-button {
  background-color: #006E33;
  border-color: #006E33;
  padding-inline: 20px;
}
li + li {
  margin-top: 16px;
}
</style>

<template>
  <Layout>
    <template v-if="!dataLoaded">
      <PageHeader :title="title" :items="breadcrumbs" />

      <div class="row">
        <div class="col-md-6" v-for="item in 2" :key="item">
          <div class="card" style="height: 638px">
            <div class="card-body card-body-listings">
              <div>
                <h4 class="card-title mb-4 placeholder-glow">
                  <span class="placeholder col-6"></span>
                </h4>
              </div>
              <b-card-text>
                <div v-for="block in 6" :key="block">
                  <div class="font-size-13 score-header placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </div>
                  <div>
                    <p class="placeholder-glow">
                      <span class="placeholder col-7"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-6"></span>
                      <span class="placeholder col-8"></span>
                    </p>
                  </div>
                </div>
              </b-card-text>
            </div>
          </div>
        </div>
      </div>

      <Disclaimer/>
    </template>

    <template v-if="dataLoaded && !userData.isSubscribed">
      <div class="container">
        <div class="row sidebar-subscribe">
          <div>You are not currently subscribed to a plan.</div>
        </div>
        <div class="row sidebar-subscribe">
         <Subscribe />
        </div>
      </div>
    </template>

    <template v-if="dataLoaded && userData.isSubscribed">
      <PageHeader :title="title" :items="breadcrumbs" />

      <!-- 2 columns – Stocks and ETFs -->
      <div class="row gx-5">

        <!-- ETFs Card -->
        <div class="col-md-6">
          <div class="card dash-card">
            <div class="card-body card-body-listings px-0">
              <div class="d-flex justify-content-between">
                <div>
                  <h4 class="card-title mb-4">ETFs</h4>
                </div>
              </div>
              <b-card-text>
                <div class="table-responsive">
                  <table
                    class="table table-centered align-middle table-sm text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th scope="col" class="ps-0 text-start">
                          {{ headers['stocks'][selectedScope][0] }}
                        </th>
                        <th scope="col" class="text-end">
                          {{ headers['stocks'][selectedScope][1] }}
                        </th>
                        <th
                          scope="col" 
                          class="text-center d-none d-sm-table-cell d-md-none d-xl-table-cell"
                        >
                          {{ headers['stocks'][selectedScope][2] }}
                        </th>
                        <th 
                          scope="col" 
                          class="text-end d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                        >
                          {{ headers['stocks'][selectedScope][3] }}
                        </th>
                        <th scope="col" class="pe-0 text-end">
                          {{ headers['stocks'][selectedScope][4] }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="itm of etfs" :key="itm.symbol">
                        <td class="td-truncate ps-0 text-start">
                          <div>
                            <h5 class="font-size-14 mb-1">
                              <router-link :to="{ name: 'etfs-symbol', params: { symbol: itm.symbol }}">
                                {{ itm.symbol }}
                              </router-link>
                            </h5>
                            <p class="text-muted mb-0">{{ itm.name }}</p>
                          </div>
                        </td>
                        <td>
                          <div class="text-end">
                            <h5 class="font-size-14 mb-0">{{ formatPrice(itm.latest_price) }}</h5>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'shortterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_7_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'mediumterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_30_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'longterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_200_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'shortterm'"
                        >
                          <div class="text-end" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_7_days) }}
                            </h5>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'mediumterm'"
                        >
                          <div class="text-end" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_30_days) }}
                            </h5>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'longterm'"
                        >
                          <div class="text-end text-truncate" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_200_days) }}
                            </h5>
                          </div>
                        </td>
                        <td class="pe-0 text-end">
                          <div class="text-end" style="width: 80px">
                            <span :class="setBadgeClass(itm, scopes[selectedScope]['snake'])">
                              {{ itm['score_labels']['score_label_' + scopes[selectedScope]['snake']] }}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="font-size-11 text-muted">
                  {{ `*) Shown here are ${scopes[selectedScope]['kebab']} ratings. The investment time frame can be changed in the left sidebar.` }}
                </div>
              </b-card-text>
            </div>
          </div>
        </div>

        <!-- Stocks Card -->
        <div class="col-md-6" v-if="userData.tier == 'premium' || userData.tier == 'pro'">
          <div class="card dash-card">
            <div class="card-body card-body-listings px-0">
              <div class="d-flex justify-content-between">
                <div>
                  <h4 class="card-title mb-4">Stocks</h4>
                </div>
              </div>
              <b-card-text>
                <div class="table-responsive">
                  <table
                    class="table table-centered align-middle table-sm text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th scope="col" class="ps-0 text-start">
                          {{ headers['stocks'][selectedScope][0] }}
                        </th>
                        <th scope="col" class="text-end">
                          {{ headers['stocks'][selectedScope][1] }}
                        </th>
                        <th
                          scope="col" 
                          class="text-center d-none d-sm-table-cell d-md-none d-xl-table-cell"
                        >
                          {{ headers['stocks'][selectedScope][2] }}
                        </th>
                        <th 
                          scope="col" 
                          class="text-end d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                        >
                          {{ headers['stocks'][selectedScope][3] }}
                        </th>
                        <th scope="col" class="pe-0 text-end">
                          {{ headers['stocks'][selectedScope][4] }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="itm of stocks" :key="itm.symbol">
                        <td class="td-truncate ps-0 text-start">
                          <div>
                            <h5 class="font-size-14 mb-1">
                              <router-link :to="{ name: 'stocks-symbol', params: { symbol: itm.symbol }}">
                                {{ itm.symbol }}
                              </router-link>
                            </h5>
                            <p class="text-muted mb-0">{{ itm.name }}</p>
                          </div>
                        </td>
                        <td>
                          <div class="text-end">
                            <h5 class="font-size-14 mb-0">{{ formatPrice(itm.latest_price) }}</h5>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'shortterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_7_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'mediumterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_30_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'longterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_200_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'shortterm'"
                        >
                          <div class="text-end" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_7_days) }}
                            </h5>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'mediumterm'"
                        >
                          <div class="text-end" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_30_days) }}
                            </h5>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'longterm'"
                        >
                          <div class="text-end text-truncate" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_200_days) }}
                            </h5>
                          </div>
                        </td>
                        <td class="pe-0 text-end">
                          <div class="text-end" style="width: 80px">
                            <span :class="setBadgeClass(itm, scopes[selectedScope]['snake'])">
                              {{ itm['score_labels']['score_label_' + scopes[selectedScope]['snake']] }}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="font-size-11 text-muted">
                  {{ `*) Shown here are ${scopes[selectedScope]['kebab']} ratings. The investment time frame can be changed in the left sidebar.` }}
                </div>
              </b-card-text>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-else>
          <div class="card dash-card">
            <div class="card-body card-body-listings px-0">
              <div class="d-flex justify-content-between">
                <div>
                  <h4 class="card-title mb-4">
                    Stocks
                    <i class="fas fa-lock ms-1"></i>
                  </h4>
                </div>
              </div>
              <b-card-text>
                <div class="table-responsive">
                  <table
                    class="table table-centered align-middle table-sm text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th scope="col" class="ps-0 text-start">
                          {{ headers['stocks'][selectedScope][0] }}
                        </th>
                        <th scope="col" class="text-end">
                          {{ headers['stocks'][selectedScope][1] }}
                        </th>
                        <th
                          scope="col" 
                          class="text-center d-none d-sm-table-cell d-md-none d-xl-table-cell"
                        >
                          {{ headers['stocks'][selectedScope][2] }}
                        </th>
                        <th 
                          scope="col" 
                          class="text-end d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                        >
                          {{ headers['stocks'][selectedScope][3] }}
                        </th>
                        <th scope="col" class="pe-0 text-end">
                          {{ headers['stocks'][selectedScope][4] }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="itm of stocks" :key="itm.symbol">
                        <td class="td-truncate ps-0 text-start">
                          <div>
                            <h5 class="font-size-14 mb-1">
                                {{ itm.symbol }}
                            </h5>
                            <p class="text-muted mb-0">{{ itm.name }}</p>
                          </div>
                        </td>
                        <td>
                          <div class="text-end">
                            <h5 class="font-size-14 mb-0">{{ formatPrice(itm.latest_price) }}</h5>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'shortterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_7_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'mediumterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_30_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'longterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_200_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'shortterm'"
                        >
                          <div class="text-end" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_7_days) }}
                            </h5>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'mediumterm'"
                        >
                          <div class="text-end" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_30_days) }}
                            </h5>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'longterm'"
                        >
                          <div class="text-end text-truncate" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_200_days) }}
                            </h5>
                          </div>
                        </td>
                        <td class="pe-0 text-end">
                          <div class="text-end" style="width: 80px">
                            <span class="badge badge-locked">
                              <i class="fas fa-lock mx-3"></i>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="font-size-11 text-muted">
                  {{ `*) Shown here are ${scopes[selectedScope]['kebab']} ratings. The investment time frame can be changed in the left sidebar.` }}
                </div>
              </b-card-text>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->

      <!-- 2 columns – Cryptos and something else -->
      <div class="row gx-5">

        <!-- Cryptos card -->
        <div class="col-md-6" v-if="userData.tier == 'pro'">
          <div class="card dash-card">
            <div class="card-body card-body-listings px-0">
              <div class="d-flex justify-content-between align-items-start">
                <div>
                  <h4 class="card-title mb-4">Cryptos</h4>
                </div>
              </div>
              <b-card-text>
                <div class="table-responsive">
                  <table
                    class="table table-centered align-middle table-sm text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th scope="col" class="ps-0"></th>
                        <th scope="col">Name</th>
                        <th scope="col" class="text-end">Price</th>
                        <th
                          scope="col" 
                          class="text-center d-none d-sm-table-cell d-md-none d-xl-table-cell"
                        >
                          {{ headers['cryptos'][selectedScope][3] }}
                        </th>
                        <th 
                          scope="col" 
                          class="text-end d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                        >
                          {{ headers['cryptos'][selectedScope][4] }}
                        </th>
                        <th scope="col" class="pe-0 text-end">Rating*</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="itm of cryptos" :key="itm.symbol">
                        <td style="width: 36px" class="ps-0">
                          <div class="font-size-22 text-start">
                            <img
                              :src="`/images/cryptoicons/${itm.symbol.toLowerCase()}.svg`"
                              onerror="this.src='/images/cryptoicons/generic.svg'"
                              alt
                              height="22"
                            />
                          </div>
                        </td>
                        <td class="td-truncate">
                          <div>
                            <h5 class="font-size-14 mb-1">
                              <router-link :to="{ name: 'crypto-symbol', params: { symbol: itm.symbol }}">
                                {{ itm.symbol }}
                              </router-link>
                            </h5>
                            <p class="text-muted mb-0">{{ itm.name }}</p>
                          </div>
                        </td>
                        <td>
                          <div class="text-end">
                            <h5 class="font-size-14 mb-0">{{ formatPrice(itm.latest_price) }}</h5>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'shortterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_7_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'mediumterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_30_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'longterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_200_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'shortterm'"
                        >
                          <div class="text-end" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_7_days) }}
                            </h5>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'mediumterm'"
                        >
                          <div class="text-end" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_30_days) }}
                            </h5>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'longterm'"
                        >
                          <div class="text-end text-truncate" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_200_days) }}
                            </h5>
                          </div>
                        </td>
                        <td class="pe-0 text-end">
                          <div class="text-end" style="width: 80px">
                            <span :class="setBadgeClass(itm, scopes[selectedScope]['snake'])">
                              {{ itm['score_labels']['score_label_' + scopes[selectedScope]['snake']] }}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="font-size-11 text-muted">
                  {{ `*) Shown here are ${scopes[selectedScope]['kebab']} ratings. The investment time frame can be changed in the left sidebar.` }}
                </div>
              </b-card-text>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-else>
          <div class="card dash-card">
            <div class="card-body card-body-listings px-0">
              <div class="d-flex justify-content-between align-items-start">
                <div>
                  <h4 class="card-title mb-4">
                    Cryptos
                    <i class="fas fa-lock ms-1"></i>
                  </h4>
                </div>
              </div>
              <b-card-text>
                <div class="table-responsive">
                  <table
                    class="table table-centered align-middle table-sm text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th scope="col" class="ps-0"></th>
                        <th scope="col">Name</th>
                        <th scope="col" class="text-end">Price</th>
                        <th
                          scope="col" 
                          class="text-center d-none d-sm-table-cell d-md-none d-xl-table-cell"
                        >
                          {{ headers['cryptos'][selectedScope][3] }}
                        </th>
                        <th 
                          scope="col" 
                          class="text-end d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                        >
                          {{ headers['cryptos'][selectedScope][4] }}
                        </th>
                        <th scope="col" class="pe-0 text-end">Rating*</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="itm of cryptos" :key="itm.symbol">
                        <td style="width: 36px" class="ps-0">
                          <div class="font-size-22 text-start">
                            <img
                              :src="`/images/cryptoicons/${itm.symbol.toLowerCase()}.svg`"
                              onerror="this.src='/images/cryptoicons/generic.svg'"
                              alt
                              height="22"
                            />
                          </div>
                        </td>
                        <td class="td-truncate">
                          <div>
                            <h5 class="font-size-14 mb-1">
                              {{ itm.symbol }}
                            </h5>
                            <p class="text-muted mb-0">{{ itm.name }}</p>
                          </div>
                        </td>
                        <td>
                          <div class="text-end">
                            <h5 class="font-size-14 mb-0">{{ formatPrice(itm.latest_price) }}</h5>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'shortterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_7_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'mediumterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_30_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td
                          class="d-none d-sm-table-cell d-md-none d-xl-table-cell" 
                          v-if="selectedScope === 'longterm'"
                        >
                          <div class="text-center" style="width: 80px">
                            <apexchart
                              class="apex-charts"
                              height="30"
                              width="60"
                              type="area"
                              dir="ltr"
                              :series="toSparklineSeries(itm.sparklines.close_last_200_days, itm.symbol)"
                              :options="getSparklineChartOptions(itm)"
                            ></apexchart>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'shortterm'"
                        >
                          <div class="text-end" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_7_days) }}
                            </h5>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'mediumterm'"
                        >
                          <div class="text-end" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_30_days) }}
                            </h5>
                          </div>
                        </td>
                        <td 
                          class="d-none d-sm-table-cell d-md-none d-xxl-table-cell"
                          v-if="selectedScope === 'longterm'"
                        >
                          <div class="text-end text-truncate" style="width: 70px">
                            <h5 class="font-size-14 text-muted mb-0">
                              {{ formatPercent(itm.rel_deltas.rel_delta_200_days) }}
                            </h5>
                          </div>
                        </td>
                        <td class="pe-0 text-end">
                          <div class="text-end" style="width: 80px">
                            <span class="badge badge-locked">
                              <i class="fas fa-lock mx-3"></i>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="font-size-11 text-muted">
                  {{ `*) Shown here are ${scopes[selectedScope]['kebab']} ratings. The investment time frame can be changed in the left sidebar.` }}
                </div>
              </b-card-text>
            </div>
          </div>
        </div>

        <!-- Knowledge Card -->
        <div class="col-md-6">
          <div class="card dash-card">
            <div class="card-body card-body-listings px-0">
              <div class="d-flex justify-content-between">
                <div>
                  <h4 class="card-title mb-4">Knowledge Base</h4>
                </div>
              </div>
              <b-card-text>
                <div class="row mt-1">
                  <div class="col-xl-6">
                    <ul class="fa-ul">
                      <li><span class="fa-li"><i class="fa fa-rocket me-3"></i></span><a href="">Long-Term Investing</a></li>
                      <li><span class="fa-li"><i class="fa fa-rocket me-3"></i></span><a href="">Three-Fund Portfolio</a></li>
                      <li><span class="fa-li"><i class="fa fa-rocket me-3"></i></span><a href="">Day Trading</a></li>
                      <li><span class="fa-li"><i class="fa fa-rocket me-3"></i></span><a href="">Algorithmic Trading</a></li>
                    </ul>
                  </div>
                  <div class="col-xl-6">
                    <ul class="fa-ul">
                      <li><span class="fa-li"><i class="fa fa-rocket me-3"></i></span><a href="">Cryptocurrency Risks</a></li>
                      <li><span class="fa-li"><i class="fa fa-rocket me-3"></i></span><a href="">Mutual Funds vs. Stocks</a></li>
                      <li><span class="fa-li"><i class="fa fa-rocket me-3"></i></span><a href="">Key Metrics for Stock Investing</a></li>
                      <li><span class="fa-li"><i class="fa fa-rocket me-3"></i></span><a href="">Value vs. Growth</a></li>
                    </ul>
                  </div>
                </div>
              </b-card-text>
            </div>
          </div>
        </div>

      </div>
      <!-- end row -->
      
      <Disclaimer/>
    </template>
  </Layout>
</template>
